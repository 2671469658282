html {
  height: 100%;
}
body {
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
  background-color: #e5e5e5;
  min-height: 100%;
}
header {
  background-color: #131D37;
  color: white;
  padding: 5rem 0;
}
h1 {
  color: white;
  font-weight: 900;
  text-align: center;
  font-size: 3rem;
}